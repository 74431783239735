import * as React from "react"
import Seo from "../components/seo"
import "../assets/css/style.css"
import "../assets/css/layout.css"
import "../assets/css/blog-style.css"
import { graphql } from "gatsby"
import Blog from "../components/blog/blog"
import { languages } from "../const/languages"

const BlogPage = ({ data }) => <Blog data={data} lang={languages.EN}/>

export const Head = () => {
  return (
    <>
      <Seo
        title="AR-Labs.io | Read more about AR and VTO for your business"
        description="This page is for you if you want to know what it means and how to introduce augmented reality or virtual try-on in your business. News, reports, and AR gossip!"
        canonicalURL="https://ar-labs.io/blog"
      />
    </>
  )
}

export const BlogContentQuery = graphql`
  query BlogPageQuery {
    allWpPost(filter: {tags: {nodes: {elemMatch: {slug: {eq: "language-en"}}}}}) {
      nodes {
        id
        title
        date
        categories {
          nodes {
            name
          }
        }
        excerpt
        featuredImage {
          node {
            publicUrl
          }
        }
        content
        author {
          node {
            name
            avatar {
              height
              width
              url
            }
          }
        }
      }
    }
  }
`

export default BlogPage
